import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import classNames from 'classnames';

import useGetPackage from '../../shared/hooks/useGetPackage/useGetPackage';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../shared/components/Icon/Icon';
import Spinner from '../../shared/components/Spinner/Spinner';

import {
  EMAIL,
  FB_URL,
  IG_URL,
  PHONE_NUMBER,
} from '../../constants/global-constants';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './PackageDetail.module.scss';

const PackageDetail = () => {
  const { t } = useTranslation();

  const urlSplit = window.location.href.split('/');
  const packageId = urlSplit[urlSplit.length - 1].split('?')[0];

  const { data, isLoading, refetch } = useGetPackage(Number(packageId), false);

  useSetPageTitle({
    pageTitle: data?.title
      ? `${data.title} | ${t('general.website-name')}`
      : t('general.website-name'),
  });

  React.useEffect(() => {
    if (packageId) {
      refetch();
    }
  }, [packageId]);

  return (
    <div className={styles.packageContainer}>
      {isLoading ? (
        <div className={styles.packageLoader}>
          <Spinner variant='accent' />
        </div>
      ) : (
        <div
          className={classNames(commonStyles.container, styles.packageContent)}>
          <div>
            {data && (
              <>
                <div className={styles.packageDetails}>
                  <h1>{data.title}</h1>
                  {data.price_min && data.price_max && (
                    <p className={styles.packagePrice}>
                      <span>
                        {data.price_min} / {data.price_max}{' '}
                        {t('general.currency')}{' '}
                      </span>
                      <span>{t('home.packages.frequency.monthly')}</span>
                    </p>
                  )}
                  <div>
                    <p className={styles.packageHeading}>
                      {t('packages.recommended-label')}
                    </p>
                    <div className={styles.packageInfo}>
                      {parse(data.target)}
                    </div>
                  </div>
                </div>
                <div className={styles.packageDetails}>
                  <div>
                    <p className={styles.packageHeading}>
                      {t('packages.description-heading')}
                    </p>
                    <div className={styles.packageInfo}>
                      {parse(data.description)}
                    </div>
                  </div>
                  <div>
                    <p className={styles.packageHeading}>
                      {t('packages.content')}
                    </p>
                    <div className={styles.packageInfo}>
                      {parse(data.features)}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.packageContact}>
            <span>{PHONE_NUMBER}</span>
            <span className={styles.packageContactSeparator}>|</span>
            <span>{EMAIL}</span>
            <span className={styles.packageContactSeparator}>|</span>
            <span>
              <a href={FB_URL} target='_blank' rel='noopener noreferrer'>
                <Icon
                  name='fa-brands fa-facebook'
                  size={24}
                  color='icon-black'
                />
              </a>
            </span>
            <span className={styles.packageContactSeparator}>|</span>
            <span>
              <a href={IG_URL} target='_blank' rel='noopener noreferrer'>
                <Icon
                  name='fa-brands fa-instagram'
                  size={24}
                  color='icon-black'
                />
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageDetail;
