const DEFAULT_IMAGE_ALT = 'Sergiu Matei';

export const IS_DEV = window.location.href.includes('localhost');

export const DEV_BASE_URL = 'http://localhost/sergiumatei/';
export const PROD_BASE_URL = 'https://sergiumatei.ro/';

export default DEFAULT_IMAGE_ALT;

export const BASE_APIS_URL = IS_DEV
  ? `${DEV_BASE_URL}/wp-json/wp/v2`
  : `${PROD_BASE_URL}/wp-json/wp/v2`;

export const BASE_URL = `${IS_DEV ? DEV_BASE_URL : PROD_BASE_URL}wp-json`;

export const EMAIL = 'sergiumatei33@gmail.com';

export const PHONE_NUMBER = '0744 968 847';

export const FB_URL = 'https://www.facebook.com/nutritionist.smatei';

export const IG_URL = 'https://www.instagram.com/nutritionist_sergiumatei/';

export const LOGO_URL =
  'https://sergiumatei.ro/wp-content/uploads/2024/04/logo-initials.png;';
