import classNames from 'classnames';
import styles from './Spinner.module.scss';

type TProps = {
  testId?: string;
  variant?: 'accent' | 'default' | 'black';
};

const Spinner = ({ testId, variant = 'accent' }: TProps) => {
  return (
    <div
      className={styles.spinnerModule}
      aria-label='Loading'
      data-testid={testId}>
      <div
        className={classNames(styles.spinnerDot, styles.bounce1, {
          [styles.accentDot]: variant === 'accent',
          [styles.defaultDot]: variant === 'default',
          [styles.blackDot]: variant === 'black',
        })}></div>
      <div
        className={classNames(styles.spinnerDot, styles.bounce2, {
          [styles.accentDot]: variant === 'accent',
          [styles.defaultDot]: variant === 'default',
          [styles.blackDot]: variant === 'black',
        })}></div>
      <div
        className={classNames(styles.spinnerDot, styles.bounce3, {
          [styles.accentDot]: variant === 'accent',
          [styles.defaultDot]: variant === 'default',
          [styles.blackDot]: variant === 'black',
        })}></div>
    </div>
  );
};

export default Spinner;
