import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icon from '../../components/Icon/Icon';

import routes from '../../../constants/routes';
import {
  EMAIL,
  FB_URL,
  IG_URL,
  PHONE_NUMBER,
} from '../../../constants/global-constants';

import commonStyles from '../../styles/index.module.scss';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footerModule}>
      <div
        className={classNames(commonStyles.container, styles.footerContainer)}>
        <div className={styles.footerColumn}>
          <div>
            <div className={styles.footerBlock}>
              <h6>{t('footer.contact')}</h6>
              <p>
                <span>
                  <Icon name='fa-phone' size={16} color='icon-default' />
                </span>
                <span>{PHONE_NUMBER}</span>
              </p>
              <p>
                <span>
                  <Icon name='fa-envelope' size={16} color='icon-default' />
                </span>
                <span>{EMAIL}</span>
              </p>
              <p>
                <span>
                  <Icon name='fa-location-dot' size={16} color='icon-default' />
                </span>
                <span>{t('general.city')}, Bihor</span>
              </p>
            </div>
            <div className={styles.footerBlock}>
              <h6>{t('footer.social-media')}</h6>
              <div className={styles.footerSocialMedia}>
                <a href={FB_URL} target='_blank' rel='noopener noreferrer'>
                  <Icon
                    name='fa-brands fa-facebook'
                    size={16}
                    color='icon-default'
                  />
                </a>
                <a href={IG_URL} target='_blank' rel='noopener noreferrer'>
                  <Icon
                    name='fa-brands fa-instagram'
                    size={16}
                    color='icon-default'
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footerBlock}>
            <h6>{t('footer.useful-links')}</h6>
            <ul className={styles.footerLinks}>
              <li>
                <a href={routes.about}>{t('menu.about')}</a>
              </li>
              <li>
                <a href={routes.packages}>{t('menu.packages')}</a>
              </li>
              <li>
                <a href={routes.recipes}>{t('menu.recipes')}</a>
              </li>
              <li>
                <a href={routes.privacyPolicy}>{t('menu.privacy-policy')}</a>
              </li>
              <li>
                <a href={routes.termsAndConditions}>
                  {t('menu.terms-and-conditions')}
                </a>
              </li>
              <li>
                <a href={routes.cookies}>{t('menu.cookies')}</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.footerLogos}>
          <a
            href='https://anpc.ro/ce-este-sal/'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src='https://sergiumatei.ro/wp-content/uploads/2024/04/logo-sal.png'
              alt='Solutionarea altenativa a litigiilor'
              loading='lazy'
            />
          </a>
          <a
            href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src='https://sergiumatei.ro/wp-content/uploads/2024/04/logo-sol.png'
              alt='Solutionarea online a litigiilor'
              loading='lazy'
            />
          </a>
        </div>
      </div>
      <div className={styles.footerCopyright}>
        <Trans
          i18nKey='footer.copyright'
          values={{ year: new Date().getFullYear() }}
        />
      </div>
    </footer>
  );
};

export default Footer;
