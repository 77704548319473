import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import useGetService from '../../shared/hooks/useGetService/useGetService';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../shared/components/Icon/Icon';
import Spinner from '../../shared/components/Spinner/Spinner';

import {
  EMAIL,
  FB_URL,
  IG_URL,
  PHONE_NUMBER,
} from '../../constants/global-constants';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './ServiceDetail.module.scss';

const ServiceDetail = () => {
  const { t } = useTranslation();

  const urlSplit = window.location.href.split('/');
  const serviceId = urlSplit[urlSplit.length - 1].split('?')[0];

  const { data, isLoading, refetch } = useGetService(Number(serviceId), false);

  useSetPageTitle({
    pageTitle: data?.title
      ? `${data.title} | ${t('general.website-name')}`
      : t('general.website-name'),
  });

  React.useEffect(() => {
    if (serviceId) {
      refetch();
    }
  }, [serviceId]);

  return (
    <div className={styles.serviceContainer}>
      {isLoading ? (
        <div className={styles.serviceLoader}>
          <Spinner variant='black' />
        </div>
      ) : (
        <div
          className={classNames(commonStyles.container, styles.serviceContent)}>
          <div>
            {data && (
              <>
                <div className={styles.serviceDetails}>
                  <h1>{data.title}</h1>
                  <div>
                    <p className={styles.serviceHeading}>
                      {t('services.subjects')}
                    </p>
                    <div className={styles.serviceInfo}>
                      {parse(data.subject)}
                    </div>
                  </div>
                  <div>
                    <p className={styles.serviceHeading}>
                      {t('services.service-description')}
                    </p>
                    <div className={styles.serviceInfo}>
                      {parse(data.content)}
                    </div>
                  </div>
                </div>
                <div className={styles.serviceDetails}>
                  <div>
                    <p className={styles.serviceHeading}>
                      {t('services.other-details')}
                    </p>
                    <div className={styles.serviceInfo}>
                      {parse(data.details)}
                    </div>
                  </div>
                  <div className={styles.serviceImages}>
                    <img src={data.image} alt={data.title} loading='lazy' />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.serviceContact}>
            <span>{PHONE_NUMBER}</span>
            <span className={styles.serviceContactSeparator}>|</span>
            <span>{EMAIL}</span>
            <span className={styles.serviceContactSeparator}>|</span>
            <span>
              <a href={FB_URL} target='_blank' rel='noopener noreferrer'>
                <Icon
                  name='fa-brands fa-facebook'
                  size={24}
                  color='icon-black'
                />
              </a>
            </span>
            <span className={styles.serviceContactSeparator}>|</span>
            <span>
              <a href={IG_URL} target='_blank' rel='noopener noreferrer'>
                <Icon
                  name='fa-brands fa-instagram'
                  size={24}
                  color='icon-black'
                />
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceDetail;
