import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';

import consultations from '../../../../images/consultations-orange.png';
import events from '../../../../images/events-green.png';
import recipes from '../../../../images/recipes-purple.png';

import commonStyles from '../../../../shared/styles/index.module.scss';
import pageStyles from '../../AboutUs.module.scss';
import styles from './Experience.module.scss';

const Experience = () => {
  const { t } = useTranslation();

  return (
    <section
      className={classNames(pageStyles.pageSection, styles.experienceSection)}>
      <div className={commonStyles.container}>
        <div className={pageStyles.pageSectionRow}>
          <div className={commonStyles.sectionTitle}>
            <HeadingLine />
            <HeadingLine />
            <h3 className={pageStyles.pageSectionTitleContent}>
              {t('about.sections.experience.title')}
            </h3>
          </div>
          <div className={styles.servicesRow}>
            <article className={styles.serviceBlock}>
              <div className={styles.serviceHeading}>
                <h6>{t('about.sections.experience.menus')}</h6>
                <p>{t('about.sections.experience.menus-desc')}</p>
              </div>
              <div className={styles.serviceImage}>
                <img src={recipes} alt='Retete' loading='lazy' />
              </div>
            </article>
            <article className={styles.serviceBlock}>
              <div className={styles.serviceHeading}>
                <h6>{t('about.sections.experience.events')}</h6>
                <p>{t('about.sections.experience.events-desc')}</p>
              </div>
              <div className={styles.serviceImage}>
                <img src={events} alt='Evenimente' loading='lazy' />
              </div>
            </article>
            <article className={styles.serviceBlock}>
              <div className={styles.serviceHeading}>
                <h6>{t('about.sections.experience.consultations')}</h6>
                <p>{t('about.sections.experience.consultations-desc')}</p>
              </div>
              <div className={styles.serviceImage}>
                <img src={consultations} alt='Consultatii' loading='lazy' />
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
