import classNames from 'classnames';
import styles from './HeadingLine.module.scss';

type TProps = {
  variant?: 'accent' | 'secondary' | 'tertiary' | 'default' | 'white';
};

const HeadingLine = ({ variant = 'accent' }: TProps) => {
  return (
    <span
      className={classNames(styles.headingLines, {
        [styles.accentHeadingLine]: variant === 'accent',
        [styles.secondaryHeadingLine]: variant === 'secondary',
        [styles.tertiaryHeadingLine]: variant === 'tertiary',
        [styles.defaultHeadingLine]: variant === 'default',
        [styles.whiteHeadingLine]: variant === 'white',
      })}>
      /
    </span>
  );
};

export default HeadingLine;
