import React from 'react';

type TProps = {
  elementRef: any;
};

const useIsInViewport = ({ elementRef }: TProps) => {
  const [isInViewport, setIsInViewport] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (!elementRef.current) return;
      const boundingClientRect = (
        elementRef.current as any
      ).getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Check if the top and bottom of the element are within the viewport
      if (
        boundingClientRect.top >= 0 &&
        boundingClientRect.bottom <= windowHeight
      ) {
        setIsInViewport(true);
      } else {
        setIsInViewport(false);
      }
    };

    // Event listener for scrolling
    window.addEventListener('scroll', handleScroll);

    // Initial check when component mounts
    handleScroll();

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    isInViewport,
  };
};

export default useIsInViewport;
