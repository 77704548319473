import React from 'react';
import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

const useRequest = <T extends { data: Record<string, any> }>(
  url: string,
  queryKey: string,
  shouldQuery: boolean,
  page?: number,
) => {
  const [data, setData] = React.useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<AxiosError | undefined>(undefined);

  const { refetch } = useQuery(
    [queryKey, page],
    () => {
      setIsLoading(true);

      axios
        .request({
          method: 'GET',
          url,
        })
        .then((response) => {
          setData(response.data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    {
      enabled: shouldQuery,
    },
  );

  return {
    data: (data as T)?.data,
    isLoading,
    error,
    refetch,
  };
};

export default useRequest;
