import React from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import classNames from 'classnames';

import useIsInViewport from '../../../../shared/hooks/useIsInViewport/useIsInViewport';
import useGetInformation from '../../../../shared/hooks/useGetInformation/useGetInformation';

import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';
import Button from '../../../../shared/components/Button/Button';
import Icon from '../../../../shared/components/Icon/Icon';

import routes from '../../../../constants/routes';

import aboutUsPicOne from '../../../../images/aboutus-pic.jpg';
import aboutUsPicTwo from '../../../../images/aboutus-pic-two.jpg';

import commonStyles from '../../../../shared/styles/index.module.scss';
import styles from './About.module.scss';

type TCounterBlockProps = {
  endValue: number;
};

const CounterBlock = ({ endValue }: TCounterBlockProps) => (
  <CountUp delay={0.5} duration={3} end={endValue} suffix='+' />
);

const About = () => {
  const { t } = useTranslation();
  const elementRef = React.useRef(null);
  const [hasShownCounters, setHasShownCounters] = React.useState(false);

  const { isInViewport } = useIsInViewport({ elementRef });

  const { data } = useGetInformation();

  const counterValues = {
    years_of_experience: data?.years_of_experience,
    customers: data?.customers,
    recipes: data?.recipes,
  };

  const handleRedirect = () => {
    window.location.href = routes.about;
  };

  React.useEffect(() => {
    if (!hasShownCounters && isInViewport) {
      setHasShownCounters(true);
    }
  }, [hasShownCounters, isInViewport]);

  return (
    <section
      className={styles.aboutUsModule}
      data-testid='homepage-about-us-section'>
      <div className={classNames(styles.aboutUsRow, commonStyles.container)}>
        <div className={styles.aboutUsContainer}>
          <div className={styles.aboutUsImages}>
            <img src={aboutUsPicOne} alt='Imagine mancare' loading='lazy' />
            <img src={aboutUsPicTwo} alt='Imagine mancare' loading='lazy' />
          </div>
          <article className={styles.aboutUsContent}>
            <div
              className={classNames(
                styles.aboutUsTitle,
                commonStyles.sectionTitle,
              )}>
              <HeadingLine />
              <HeadingLine />
              <h3>{t('home.about.headline')}</h3>
            </div>
            <h1>{t('home.about.motto')}</h1>
            <p>{t('home.about.intro')}</p>
            <Button
              label={t('general.read-more')}
              variant='secondary'
              onClick={handleRedirect}>
              <Icon
                name='fa-regular fa-circle-chevron-right'
                size={14}
                color='icon-white'
              />
            </Button>
          </article>
        </div>
        <div ref={elementRef} className={styles.aboutUsStatistics}>
          {hasShownCounters && (
            <>
              <div className={styles.aboutUsStatisticBlock}>
                <h4 className={styles.statisticHeading}>
                  <CounterBlock endValue={counterValues.years_of_experience} />
                </h4>
                <span className={styles.statisticLabel}>
                  {t('home.about.statistics.years-of-experience')}
                </span>
              </div>
              <div className={styles.aboutUsStatisticBlock}>
                <h4 className={styles.statisticHeading}>
                  <CounterBlock endValue={counterValues.customers} />
                </h4>
                <span className={styles.statisticLabel}>
                  {t('home.about.statistics.customers')}
                </span>
              </div>
              <div className={styles.aboutUsStatisticBlock}>
                <h4 className={styles.statisticHeading}>
                  <CounterBlock endValue={counterValues.recipes} />
                </h4>
                <span className={styles.statisticLabel}>
                  {t('home.about.statistics.recipes')}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default About;
