import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';

import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import privacyPolicyImg from '../../images/privacy-policy.png';

import commonStyles from '../../shared/styles/index.module.scss';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.privacy-policy')} | ${t('general.website-name')}`,
  });

  return (
    <div className={commonStyles.container}>
      <div
        className={classNames(
          commonStyles.legalPageHeader,
          commonStyles.container,
        )}>
        <div>
          <h1 className={commonStyles.legalPageTitle}>
            {t('privacy-policy.title')}
          </h1>
        </div>
        <div>
          <img
            src={privacyPolicyImg}
            alt={t('privacy-policy.title')}
            loading='lazy'
          />
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.legalPageContent,
          commonStyles.container,
        )}>
        <div className={commonStyles.legalPageContentBlock}>
          <p className={commonStyles.legalPageContentRow}>
            {t('privacy-policy.section-one.line-one')}
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('privacy-policy.section-two.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            <Trans i18nKey='privacy-policy.section-two.line-one' />
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('privacy-policy.section-three.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            {t('privacy-policy.section-three.line-one')}
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('privacy-policy.section-four.title')}
          </h4>
          <ul>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('privacy-policy.section-four.line-one')}
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('privacy-policy.section-four.line-two')}
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('privacy-policy.section-four.line-three')}
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('privacy-policy.section-four.line-four')}
            </li>
          </ul>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('privacy-policy.section-five.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            {t('privacy-policy.section-five.line-one')}
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('privacy-policy.section-six.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            <Trans i18nKey='privacy-policy.section-six.line-one' />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
