import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Carousel from 'nuka-carousel';

import useWindowSize from '../../../../shared/hooks/useWindowSize/useWindowSize';
import useGetPackages from '../../../../shared/hooks/useGetPackages/useGetPackages';

import Icon from '../../../../shared/components/Icon/Icon';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';
import Package from './components/Package';

import { TPackage } from '../../../../shared/types/TPackagesResponse';

import commonStyles from '../../../../shared/styles/index.module.scss';
import styles from './Packages.module.scss';

const Packages = () => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const { data: packagesList, isLoading } = useGetPackages();

  const isDesktop = windowSize.width >= 1024;
  const isTablet = windowSize.width >= 640 && windowSize.width < 1024 ? 2 : 1;

  const carouselConfig = {
    cellSpacing: isDesktop ? 36 : 8,
    defaultControlsConfig: {
      pagingDotsStyle: {
        display: 'none',
      },
      nextButtonClassName: styles.arrowRight,
      nextButtonText: (
        <Icon name='fa-circle-chevron-right' color='icon-black' size={20} />
      ),
      prevButtonText: (
        <Icon name='fa-circle-chevron-left' color='icon-black' size={20} />
      ),
      prevButtonClassName: styles.arrowLeft,
    },
    slidesToShow: isDesktop ? 3 : isTablet,
    slidesToScroll: 1,
  };

  return (
    <section
      className={styles.packagesModule}
      data-testid='homepage-packages-section'>
      {isLoading ? (
        <Spinner variant='black' />
      ) : (
        <div
          className={classNames(
            commonStyles.container,
            styles.packagesContainer,
          )}>
          <div
            className={classNames(
              styles.packagesTitle,
              commonStyles.sectionTitle,
            )}>
            <HeadingLine />
            <HeadingLine />
            <h3>{t('home.packages.headline')}</h3>
          </div>
          <h1 className={styles.packagesAction}>
            {t('home.packages.subheadline')}
          </h1>
          <div className={styles.packagesList}>
            <Carousel {...carouselConfig}>
              {!!packagesList?.packages.length &&
                packagesList.packages.map(
                  ({
                    id,
                    description,
                    features,
                    price_max,
                    price_min,
                    title,
                  }: TPackage) => (
                    <Package
                      key={id}
                      id={id}
                      description={description}
                      features={features}
                      price_max={price_max}
                      price_min={price_min}
                      title={title}
                    />
                  ),
                )}
            </Carousel>
          </div>
        </div>
      )}
    </section>
  );
};

export default Packages;
