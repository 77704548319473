import { useTranslation } from 'react-i18next';

import { IS_DEV } from '../../../../constants/global-constants';

import Icon from '../../../../shared/components/Icon/Icon';

import styles from './Package.module.scss';

type TProps = {
  id: number;
  description: React.ReactNode;
  features: React.ReactNode;
  price_max: number;
  price_min: number;
  title: string;
};

const Package = ({
  id,
  description,
  features,
  price_max,
  price_min,
  title,
}: TProps) => {
  const { t } = useTranslation();

  const BASE_PATH = IS_DEV ? '/sergiumatei/' : '/';

  return (
    <article className={styles.package}>
      <div className={styles.packageContent}>
        <div className={styles.packageHeader}>
          {price_min && price_max && (
            <p className={styles.packagePrice}>
              {price_min} / {price_max} {t('general.currency')}{' '}
              <span>{t('home.packages.frequency.monthly')}</span>
            </p>
          )}
          <h6>{title}</h6>
        </div>
        <div className={styles.packageDetails}>
          <p>{description}</p>
          <p>{t('packages.features-headline')}</p>
          <div>{features}</div>
        </div>
        <hr className={styles.packageLineSeparator} />
        <div className={styles.packageAction}>
          <a href={`${BASE_PATH}pachet/${id}`}>
            <span>{t('packages.cta')}</span>
            <Icon
              name='fa-regular fa-chevron-right'
              size={12}
              color='icon-link'
            />
          </a>
        </div>
      </div>
    </article>
  );
};

export default Package;
