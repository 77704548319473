import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TInformationResponse } from '../../types/TInformationResponse';

const useGetInformation = () => {
  const { data, isLoading, error, refetch } = request<TInformationResponse>(
    `${BASE_URL}/api/v1/information`,
    QueryKeys.Information,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetInformation;
