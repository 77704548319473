import Icon from '../../../../../shared/components/Icon/Icon';

import styles from './Testimonial.module.scss';

type TProps = {
  avatar: string;
  date: string;
  owner: string;
  text: string;
};

const Testimonial = ({ avatar, date, owner, text }: TProps) => {
  return (
    <article className={styles.testimonialItem}>
      <div className={styles.testimonialQuote}>
        <Icon name='fa-quote-right' size={40} color='icon-black' />
      </div>
      <div className={styles.testimonialText}>{text}</div>
      <hr className={styles.testimonialSeparator} />
      <div className={styles.testimonialOwner}>
        <div>
          <img src={avatar} alt='User avatar' loading='lazy' />
          <span>{owner}</span>
        </div>
        <div className={styles.testimonialDate}>{date}</div>
      </div>
    </article>
  );
};

export default Testimonial;
