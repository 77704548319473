import { format } from 'date-fns';
import { ro } from 'date-fns/locale';

const getKeyForCondition = <T extends Record<string, boolean>>(
  args: T,
): keyof T => {
  const resultObj = Object.entries(args).find(([, value]) => !!value);

  return resultObj ? resultObj[0] : '';
};

export const dateFormat = (date: string, parseFormat: string) => {
  return format(new Date(date), parseFormat, {
    locale: ro,
  });
};

export default getKeyForCondition;
