import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Button from '../../../../../shared/components/Button/Button';

import styles from './Package.module.scss';

type TProps = {
  id: number;
  description: string;
  features: string;
  price_max: number;
  price_min: number;
  title: string;
};

const Package = ({
  id,
  description,
  features,
  price_max,
  price_min,
  title,
}: TProps) => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.location.assign(`pachet/${id}`);
  };

  return (
    <article className={styles.packageItem}>
      <div className={styles.packageHeader}>
        <h3 className={styles.packageTitle}>{title}</h3>
      </div>
      <div className={styles.packageContent}>
        {price_min && price_max && (
          <h5 className={styles.packagePrice}>
            <span>
              {price_min} / {price_max} {t('general.currency')}
            </span>
            <span>{t('home.packages.frequency.monthly')}</span>
          </h5>
        )}
        <p className={styles.packageDescription}>{description}</p>
        <div className={styles.packageFeatures}>{parse(features)}</div>
      </div>
      <Button
        label={t('home.packages.cta')}
        variant='secondary'
        testId='choose-package-button'
        onClick={handleRedirect}
      />
    </article>
  );
};

export default Package;
