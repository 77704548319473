import React from 'react';
import classNames from 'classnames';

import Banner from '../Banner/Banner';
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import Menu from '../../components/Menu/Menu';

import menu from '../../../constants/menu';
import routes from '../../../constants/routes';
import { LOGO_URL } from '../../../constants/global-constants';

import commonStyles from '../../styles/index.module.scss';
import styles from './Header.module.scss';

const Header = () => {
  const [isBurgerMenuToggled, setIsBurgerMenuToggled] =
    React.useState<boolean>(false);
  const isHome = window.location.pathname === routes.home;

  return (
    <header className={styles.headerBanner}>
      <div
        className={classNames(
          {
            [styles.headerContent]: isHome,
            [styles.headerContentDefault]: !isHome,
          },
          commonStyles.container,
        )}>
        <a href='/' data-testid='header-logo-wrapper'>
          <img
            src={LOGO_URL}
            alt='Sergiu Matei'
            className={styles.logo}
            loading='lazy'
          />
        </a>
        <div className={styles.headerMenu}>
          <Menu items={menu} isBurgerMenuToggled={isBurgerMenuToggled} />
          <BurgerMenu
            isBurgerMenuToggled={isBurgerMenuToggled}
            onBurgerMenuToggle={() => setIsBurgerMenuToggled((prev) => !prev)}
          />
        </div>
      </div>
      {isHome && <Banner />}
    </header>
  );
};

export default Header;
