import Icon from '../../../shared/components/Icon/Icon';

import { IS_DEV } from '../../../constants/global-constants';

import styles from './Recipe.module.scss';

type TProps = {
  featured_image: string;
  id: number;
  portions: string;
  preparation_time: string;
  title: string;
};

const Recipe = ({
  featured_image,
  id,
  portions,
  preparation_time,
  title,
}: TProps) => {
  const BASE_PATH = IS_DEV ? '/sergiumatei/' : '/';

  return (
    <article className={styles.recipeItem}>
      <a href={`${BASE_PATH}reteta/${id}`} className={styles.recipeLink}>
        <div className={styles.recipeImage}>
          <img src={featured_image} alt={title} loading='lazy' />
        </div>
        <div className={styles.recipeContent}>
          <h6>{title}</h6>
          <div className={styles.recipeDetails}>
            <span>
              <Icon name='fa-clock' size={14} color='icon-black' />
              <span>{preparation_time}</span>
            </span>
            <span>
              <Icon name='fa-mortar-pestle' size={14} color='icon-black' />
              <span>{portions}</span>
            </span>
          </div>
        </div>
      </a>
    </article>
  );
};

export default Recipe;
