import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TPackageResponse } from '../../types/TPackageResponse';

const useGetPackage = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TPackageResponse>(
    `${BASE_URL}/api/v1/packages/${id}`,
    QueryKeys.PackageDetail,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetPackage;
