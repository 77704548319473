import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import useGetTestimonials from '../../shared/hooks/useGetTestimonials/useGetTestimonials';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Testimonial from './components/Testimonial/Testimonial';

import Button from '../../shared/components/Button/Button';
import Spinner from '../../shared/components/Spinner/Spinner';

import { dateFormat } from '../../shared/helpers/helpers';

import { TTestimonial } from '../../shared/types/TTestimonialsResponse';

import userAvatarOne from '../../images/user-avatar-one.png';
import userAvatarTwo from '../../images/user-avatar-two.png';
import userAvatarThree from '../../images/user-avatar-three.png';
import userAvatarFour from '../../images/user-avatar-four.png';
import userAvatarFive from '../../images/user-avatar-five.png';
import userAvatarSix from '../../images/user-avatar-six.png';
import userAvatarSeven from '../../images/user-avatar-seven.png';
import userAvatarEight from '../../images/user-avatar-eight.png';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './Testimonials.module.scss';

const Testimonials = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.testimonials')} | ${t('general.website-name')}`,
  });

  const { data, isLoading } = useGetTestimonials({
    offset: '-1',
  });

  const getUserAvatar = (gender: 'male' | 'female', index: number) => {
    if (index === 0 || index === 5) {
      if (gender === 'female') return userAvatarOne;

      return userAvatarTwo;
    }

    if (index === 1 || index === 6) {
      if (gender === 'female') return userAvatarThree;

      return userAvatarFour;
    }

    if (index === 2 || index === 7) {
      if (gender === 'female') return userAvatarFive;

      return userAvatarSix;
    }

    if (index === 3 || index === 8) {
      if (gender === 'female') return userAvatarSeven;

      return userAvatarEight;
    }

    if (gender === 'female') return userAvatarOne;

    return userAvatarTwo;
  };

  return (
    <div>
      <div className={styles.testimonialsHeader}>
        <div
          className={classNames(
            styles.testimonialsText,
            commonStyles.container,
          )}>
          <h1>{t('testimonials.title')}</h1>
          <p>{t('testimonials.subtitle')}</p>
          <div className={styles.actionButton}>
            <Button
              variant='emphasized'
              onClick={() =>
                window.open(
                  'https://www.facebook.com/nutritionist.smatei/reviews',
                  '_blank',
                )
              }
              label={t('testimonials.add-review')}
            />
          </div>
        </div>
        <div className={styles.testimonialsBanner}>
          <img
            src='https://sergiumatei.ro/wp-content/uploads/testimonials-banner.png'
            alt='Testimoniale'
            loading='lazy'
          />
        </div>
      </div>
      <section
        className={classNames(styles.testimonialsList, commonStyles.container)}>
        <div className={styles.testimonialsListHeader}>
          <h3>{t('testimonials.list.title')}</h3>
          <p>{t('testimonials.list.subtitle')}</p>
        </div>
        {isLoading ? (
          <Spinner variant='accent' />
        ) : (
          data?.testimonials && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 540: 1, 768: 2, 992: 3 }}>
              <Masonry gutter='24px'>
                {data?.testimonials.map(
                  (
                    { id, date, gender, owner, text }: TTestimonial,
                    index: number,
                  ) => (
                    <Testimonial
                      key={id}
                      avatar={getUserAvatar(gender, index)}
                      date={dateFormat(date, 'd MMM. yyyy')}
                      owner={owner}
                      text={text}
                    />
                  ),
                )}
              </Masonry>
            </ResponsiveMasonry>
          )
        )}
      </section>
    </div>
  );
};

export default Testimonials;
