import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Icon from '../../../../shared/components/Icon/Icon';
import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';

import { EMAIL, PHONE_NUMBER } from '../../../../constants/global-constants';

import contactImg from '../../../../images/contact.png';

import commonStyles from '../../../../shared/styles/index.module.scss';
import pageStyles from '../../AboutUs.module.scss';
import styles from './Contact.module.scss';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section
      className={classNames(pageStyles.pageSection, styles.contactSection)}>
      <article className={commonStyles.container}>
        <div className={pageStyles.pageSectionRow}>
          <div className={styles.contactSectionContent}>
            <div className={styles.contactSectionForm}>
              <img src={contactImg} alt='Contact' loading='lazy' />
              <div className={commonStyles.sectionTitle}>
                <HeadingLine variant='white' />
                <HeadingLine variant='white' />
                <h3 className={pageStyles.pageSectionTitleContent}>
                  {t('about.sections.contact.title')}
                </h3>
              </div>
              <h2>{t('about.sections.contact.subtitle')}</h2>
              <div>
                <p>
                  <span>
                    <Icon name='fa-phone' size={24} color='icon-white' />
                  </span>
                  <span>{PHONE_NUMBER}</span>
                </p>
                <p>
                  <span>
                    <Icon name='fa-envelope' size={24} color='icon-white' />
                  </span>
                  <span>{EMAIL}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Contact;
