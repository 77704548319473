import { useTranslation } from 'react-i18next';

import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import About from './components/About/About';
import Packages from './components/Packages/Packages';
import Testimonials from './components/Testimonials/Testimonials';

const Home = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.home')} | ${t('general.website-name')}`,
  });

  return (
    <div>
      <About />
      <Packages />
      <Testimonials />
    </div>
  );
};

export default Home;
