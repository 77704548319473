import { BrowserRouter, Routes as DOMRoutes, Route } from 'react-router-dom';

import Home from '../pages/Home/Home';
import AboutUs from '../pages/AboutUs/AboutUs';
import Contact from '../pages/Contact/Contact';
import Cookies from '../pages/Cookies/Cookies';
import PackageDetail from '../pages/PackageDetail/PackageDetail';
import Packages from '../pages/Packages/Packages';
import Page404 from '../pages/404/404';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import Recipes from '../pages/Recipes/Recipes';
import RecipeDetail from '../pages/RecipeDetail/RecipeDetail';
import Services from '../pages/Services/Services';
import ServiceDetail from '../pages/ServiceDetail/ServiceDetail';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import Testimonials from '../pages/Testimonials/Testimonials';

import routes from '../constants/routes';

const Routes = () => {
  return (
    <BrowserRouter>
      <DOMRoutes>
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.about} element={<AboutUs />} />
        <Route path={routes.packages} element={<Packages />} />
        <Route path={routes.packageDetail} element={<PackageDetail />} />
        <Route path={routes.testimonials} element={<Testimonials />} />
        <Route path={routes.recipes} element={<Recipes />} />
        <Route path={routes.recipeDetail} element={<RecipeDetail />} />
        <Route path={routes.services} element={<Services />} />
        <Route path={routes.serviceDetail} element={<ServiceDetail />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={routes.cookies} element={<Cookies />} />
        <Route
          path={routes.termsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route path='*' element={<Page404 />} />
      </DOMRoutes>
    </BrowserRouter>
  );
};

export default Routes;
