import { Tooltip } from 'react-tooltip';

import Icon from '../../../../../shared/components/Icon/Icon';

import styles from './Step.module.scss';

type TProps = {
  iconName: string;
  label: string;
  stepNumber: string;
};

const Step = ({ iconName, label, stepNumber }: TProps) => {
  return (
    <div className={styles.step}>
      <div className={styles.stepIcon}>
        <div>
          <Icon name={iconName} size={20} color='icon-black' iconStyle='far' />
        </div>
      </div>
      <div className={styles.stepContent}>
        <p className={styles.stepCounter}>{stepNumber}</p>
        <span
          data-tooltip-id='step-tooltip'
          data-tooltip-content={label}
          data-tooltip-place='top'>
          {label}
        </span>
        <Tooltip id='step-tooltip' />
      </div>
    </div>
  );
};

export default Step;
