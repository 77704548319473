import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TRecipeResponse } from '../../types/TRecipeResponse';

const useGetRecipe = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TRecipeResponse>(
    `${BASE_URL}/api/v1/recipes/${id}`,
    QueryKeys.RecipeDetail,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetRecipe;
