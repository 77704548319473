import classNames from 'classnames';

import styles from './Button.module.scss';

type TProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  label: string;
  testId?: string;
  variant: 'emphasized' | 'secondary' | 'tertiary' | 'default' | 'plain';
  onClick: () => void;
};

const Button: React.FC<TProps> = ({
  children,
  className,
  disabled = false,
  label,
  testId,
  variant,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.emphasizedButton]: variant === 'emphasized',
          [styles.secondaryButton]: variant === 'secondary',
          [styles.tertiaryButton]: variant === 'tertiary',
          [styles.defaultButton]: variant === 'default',
          [styles.plainButton]: variant === 'plain',
          [styles.disabledButton]: disabled,
        },
        className,
      )}
      disabled={disabled}
      type='button'
      onClick={onClick}
      data-testid={testId}>
      {label} {children}
    </button>
  );
};

export default Button;
