import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useGetRecipes from '../../shared/hooks/useGetRecipes/useGetRecipes';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Recipe from './components/Recipe';
import Button from '../../shared/components/Button/Button';
import Spinner from '../../shared/components/Spinner/Spinner';

import { TRecipe } from '../../shared/types/TRecipesResponse';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './Recipes.module.scss';

const Recipes = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.recipes')} | ${t('general.website-name')}`,
  });

  const popularRecipesRef = React.useRef<HTMLDivElement>(null);

  const { data, isLoading } = useGetRecipes();

  const handleViewRecipes = () => {
    if (popularRecipesRef.current) {
      popularRecipesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className={styles.recipesHeader}>
        <div
          className={classNames(
            styles.recipesHeaderContent,
            commonStyles.container,
          )}>
          <div className={styles.recipesText}>
            <h1>
              <span>{t('recipes.title-line-one')}</span>
              <span>{t('recipes.title-line-two')}</span>
            </h1>
            <Button
              variant='tertiary'
              label={t('recipes.heading-button')}
              onClick={handleViewRecipes}
            />
          </div>
          <div className={styles.recipesImage}>
            <img
              src='https://sergiumatei.ro/wp-content/uploads/recipes-banner.png'
              alt='Food'
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <section
        className={classNames(styles.recipesListing, commonStyles.container)}>
        <div className={styles.recipesListHeader} ref={popularRecipesRef}>
          <h3>{t('recipes.popular-recipes-title')}</h3>
        </div>
        <div>
          {isLoading ? (
            <Spinner variant='accent' />
          ) : (
            data?.recipes && (
              <div className={styles.recipesListContainer}>
                {data.recipes
                  .filter((recipe: TRecipe) => recipe.category === 'popular')
                  .map(
                    ({
                      featured_image,
                      id,
                      portions,
                      preparation_time,
                      title,
                    }: TRecipe) => (
                      <Recipe
                        key={id}
                        featured_image={featured_image}
                        id={id}
                        portions={portions}
                        preparation_time={preparation_time}
                        title={title}
                      />
                    ),
                  )}
              </div>
            )
          )}
        </div>
        <div>
          <div className={styles.recipesListHeader}>
            <h3>{t('recipes.general-recipes-title')}</h3>
          </div>
          {isLoading ? (
            <Spinner variant='accent' />
          ) : (
            <div
              className={classNames(
                styles.generalRecipesList,
                styles.recipesListContainer,
              )}>
              {data?.recipes.map(
                ({
                  featured_image,
                  id,
                  portions,
                  preparation_time,
                  title,
                }: TRecipe) => (
                  <Recipe
                    key={id}
                    featured_image={featured_image}
                    id={id}
                    portions={portions}
                    preparation_time={preparation_time}
                    title={title}
                  />
                ),
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Recipes;
