import { IS_DEV } from './global-constants';

const BASE_PATH = IS_DEV ? '/sergiumatei/' : '/';

const routes = {
  home: BASE_PATH,
  about: `${BASE_PATH}despre`,
  contact: `${BASE_PATH}contact`,
  packages: `${BASE_PATH}pachete`,
  packageDetail: `${BASE_PATH}pachet/:id`,
  recipes: `${BASE_PATH}retete`,
  recipeDetail: `${BASE_PATH}reteta/:id`,
  testimonials: `${BASE_PATH}testimoniale`,
  privacyPolicy: `${BASE_PATH}politica-de-confidentialitate`,
  termsAndConditions: `${BASE_PATH}termene-si-conditii`,
  cookies: `${BASE_PATH}cookies`,
  services: `${BASE_PATH}servicii`,
  serviceDetail: `${BASE_PATH}serviciu/:id`,
};

export default routes;
