import React from 'react';

type TProps = {
  pageTitle: string;
};

const useSetPageTitle = ({ pageTitle }: TProps) => {
  React.useEffect(() => {
    document.title = pageTitle;
  });
};

export default useSetPageTitle;
