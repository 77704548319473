import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Carousel from 'nuka-carousel';

import useWindowSize from '../../../../shared/hooks/useWindowSize/useWindowSize';
import useGetTestimonials from '../../../../shared/hooks/useGetTestimonials/useGetTestimonials';

import Icon from '../../../../shared/components/Icon/Icon';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import Testimonial from './components/Testimonial';
import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';

import { dateFormat } from '../../../../shared/helpers/helpers';

import { TTestimonial } from '../../../../shared/types/TTestimonialsResponse';

import userAvatarOne from '../../../../images/user-avatar-one.png';
import userAvatarTwo from '../../../../images/user-avatar-two.png';
import userAvatarThree from '../../../../images/user-avatar-three.png';
import userAvatarFour from '../../../../images/user-avatar-four.png';
import userAvatarFive from '../../../../images/user-avatar-five.png';
import userAvatarSix from '../../../../images/user-avatar-six.png';
import userAvatarSeven from '../../../../images/user-avatar-seven.png';
import userAvatarEight from '../../../../images/user-avatar-eight.png';
import testimonialsArrow from '../../../../images/testimonials-arrow.png';

import commonStyles from '../../../../shared/styles/index.module.scss';
import styles from './Testimonials.module.scss';

const Testimonials = () => {
  const { t } = useTranslation();

  const windowSize = useWindowSize();
  const { data: testimonialsList, isLoading } = useGetTestimonials({
    offset: '9',
  });

  const isDesktop = windowSize.width >= 1024;
  const tabletSlides =
    windowSize.width >= 640 && windowSize.width < 1024 ? 2 : 1;

  const carouselConfig = {
    autoplay: true,
    autoplayInterval: 3500,
    cellSpacing: isDesktop ? 36 : 8,
    slidesToShow: isDesktop ? 3 : tabletSlides,
    slidesToScroll: 1,
    withoutControls: true,
    wrapAround: true,
  };

  const getUserAvatar = (gender: 'male' | 'female', index: number) => {
    if (index === 0 || index === 5) {
      if (gender === 'female') return userAvatarOne;

      return userAvatarTwo;
    }

    if (index === 1 || index === 6) {
      if (gender === 'female') return userAvatarThree;

      return userAvatarFour;
    }

    if (index === 2 || index === 7) {
      if (gender === 'female') return userAvatarFive;

      return userAvatarSix;
    }

    if (index === 3 || index === 8) {
      if (gender === 'female') return userAvatarSeven;

      return userAvatarEight;
    }

    if (gender === 'female') return userAvatarOne;

    return userAvatarTwo;
  };

  return (
    <section
      className={styles.testimonialsModule}
      data-testid='homepage-testimonials-section'>
      <div
        className={classNames(
          commonStyles.container,
          styles.testimonialsContainer,
        )}>
        <div className={styles.testimonialsHeader}>
          <div>
            <div className={styles.testimonialHeaderContent}>
              <h1 className={styles.testimonialsHeading}>
                {t('home.testimonials.headline')}
              </h1>
              <div className={commonStyles.sectionTitle}>
                <HeadingLine />
                <HeadingLine />
                <h3>{t('home.testimonials.title')}</h3>
              </div>
            </div>
            <img
              src={testimonialsArrow}
              className={styles.testimonialsArrow}
              alt='Arrow'
              loading='lazy'
            />
          </div>
          <div>
            <p>
              {t('home.testimonials.cta-line-one')}{' '}
              <a href='/contact' target='_self'>
                {t('home.testimonials.cta-line-two')}
              </a>
            </p>
          </div>
        </div>
        <div className={styles.testimonialsList}>
          {isLoading ? (
            <Spinner variant='black' />
          ) : (
            <Carousel {...carouselConfig}>
              {!!testimonialsList?.testimonials.length &&
                testimonialsList.testimonials.map(
                  (
                    { id, date, gender, owner, text }: TTestimonial,
                    index: number,
                  ) => (
                    <Testimonial
                      key={id}
                      avatar={getUserAvatar(gender, index)}
                      date={dateFormat(date, 'd MMM. yyyy')}
                      owner={owner}
                      text={text}
                    />
                  ),
                )}
            </Carousel>
          )}
        </div>
      </div>
      <div className={styles.testimonialsEndQuote}>
        <Icon name='fa-quote-right' size={200} color='icon-black' />
      </div>
    </section>
  );
};

export default Testimonials;
