import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';

import reportWebVitals from './reportWebVitals';

import './index.css';

import i18n from './config/i18n/i18n';
import { queryClientConfig } from './config/react-query';

import Header from './shared/containers/Header/Header';
import Footer from './shared/containers/Footer/Footer';

const queryClient = new QueryClient(queryClientConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (root) {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <Header />
          <App />
          <Footer />
        </I18nextProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
