import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TServiceResponse } from '../../types/TServiceResponse';

const useGetService = (id: number, shouldQuery: boolean) => {
  const { data, isLoading, error, refetch } = request<TServiceResponse>(
    `${BASE_URL}/api/v1/services/${id}`,
    QueryKeys.ServiceDetail,
    shouldQuery,
  );

  return { data, isLoading, error, refetch };
};

export default useGetService;
