import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';

import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import cookiesImg from '../../images/cookies.png';

import commonStyles from '../../shared/styles/index.module.scss';

const Cookies = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.cookies')} | ${t('general.website-name')}`,
  });

  return (
    <div className={commonStyles.container}>
      <div
        className={classNames(
          commonStyles.legalPageHeader,
          commonStyles.container,
        )}>
        <div className={commonStyles.legalPageContentBlock}>
          <h1 className={commonStyles.legalPageTitle}>{t('cookies.title')}</h1>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <img src={cookiesImg} alt={t('cookies.title')} loading='lazy' />
        </div>
      </div>
      <div
        className={classNames(
          commonStyles.legalPageContent,
          commonStyles.container,
        )}>
        <div className={commonStyles.legalPageContentBlock}>
          <p className={commonStyles.legalPageContentRow}>
            {t('cookies.section-one.line-one')}
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('cookies.section-two.title')}
          </h4>
          <h5 className={commonStyles.legalPageContentSubHeadline}>
            {t('cookies.section-two.subtitle-one')}
          </h5>
          <p className={commonStyles.legalPageContentRow}>
            {t('cookies.section-two.line-one')}
          </p>
          <h5 className={commonStyles.legalPageContentSubHeadline}>
            {t('cookies.section-two.subtitle-two')}
          </h5>
          <p className={commonStyles.legalPageContentRow}>
            {t('cookies.section-two.line-two')}
          </p>
          <ul>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('cookies.section-two.line-three')}
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('cookies.section-two.line-four')}
            </li>
          </ul>
          <h5 className={commonStyles.legalPageContentSubHeadline}>
            {t('cookies.section-two.subtitle-three')}
          </h5>
          <p className={commonStyles.legalPageContentRow}>
            {t('cookies.section-two.line-five')}
          </p>
          <h5 className={commonStyles.legalPageContentSubHeadline}>
            {t('cookies.section-two.subtitle-four')}
          </h5>
          <p className={commonStyles.legalPageContentRow}>
            {t('cookies.section-two.line-six')}
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('cookies.section-three.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            <Trans i18nKey='cookies.section-three.line-one' />
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('cookies.section-four.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            <Trans i18nKey='cookies.section-four.line-one' />
          </p>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('cookies.section-five.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            {t('cookies.section-five.line-one')}
          </p>
          <ul>
            <li className={commonStyles.legalPageContentLineItem}>
              <a
                href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=ro'
                target='_blank'
                rel='noopener noreferrer'>
                Google Chrome
              </a>
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              <a
                href='https://support.mozilla.org/ro/kb/activarea-si-dezactivarea-cookie-urilor'
                target='_blank'
                rel='noopener noreferrer'>
                Mozilla Firefox
              </a>
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              <a
                href='https://support.microsoft.com/ro-ro/help/278835/how-to-delete-cookie-files-in-internet-explorer'
                target='_blank'
                rel='noopener noreferrer'>
                Internet Explorer
              </a>
            </li>
            <li className={commonStyles.legalPageContentLineItem}>
              {t('cookies.section-five.line-two')}
            </li>
          </ul>
        </div>
        <div className={commonStyles.legalPageContentBlock}>
          <h4 className={commonStyles.legalPageContentHeadline}>
            {t('cookies.section-six.title')}
          </h4>
          <p className={commonStyles.legalPageContentRow}>
            <Trans i18nKey='cookies.section-six.line-one' />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
