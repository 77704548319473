import classNames from 'classnames';

import routes from '../../../constants/routes';

import styles from './BurgerMenu.module.scss';

type TProps = {
  isBurgerMenuToggled: boolean;
  onBurgerMenuToggle: () => void;
};

const BurgerMenu = ({ isBurgerMenuToggled, onBurgerMenuToggle }: TProps) => {
  const isHome = window.location.pathname === routes.home;

  return (
    <button
      type='button'
      onClick={onBurgerMenuToggle}
      className={classNames(styles.burgerMenu, {
        [styles.burgerMenuHp]: isHome,
      })}
      data-testid={
        isBurgerMenuToggled ? 'mobile-burger-menu-active' : 'mobile-burger-menu'
      }>
      <div
        className={classNames(styles.burgerMenuLine, styles.lineOne, {
          [styles.lineOneActive]: isBurgerMenuToggled,
        })}></div>
      <div
        className={classNames(styles.burgerMenuLine, {
          [styles.lineTwoActive]: isBurgerMenuToggled,
        })}></div>
      <div
        className={classNames(styles.burgerMenuLine, styles.lineThree, {
          [styles.lineThreeActive]: isBurgerMenuToggled,
        })}></div>
      <div className={styles.clear}></div>
    </button>
  );
};

export default BurgerMenu;
