import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TPackagesResponse } from '../../types/TPackagesResponse';

const useGetPackages = () => {
  const { data, isLoading, error, refetch } = request<TPackagesResponse>(
    `${BASE_URL}/api/v1/packages`,
    QueryKeys.Packages,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetPackages;
