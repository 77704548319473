import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';
import Step from './components/Step';

import commonStyles from '../../../../shared/styles/index.module.scss';
import pageStyles from '../../AboutUs.module.scss';
import styles from './Steps.module.scss';

const Steps = () => {
  const { t } = useTranslation();

  const steps = [
    {
      value: 'one',
      label: t('about.sections.steps.protocol-pre-steps.step-one'),
    },
    {
      value: 'two',
      label: t('about.sections.steps.protocol-pre-steps.step-two'),
    },
    {
      value: 'three',
      label: t('about.sections.steps.protocol-pre-steps.step-three'),
    },
    {
      value: 'four',
      label: t('about.sections.steps.protocol-pre-steps.step-four'),
    },
    {
      value: 'five',
      label: t('about.sections.steps.protocol-pre-steps.step-five'),
    },
  ];

  const getIconName = (index: number) => {
    if (index === 0) return 'fa-lightbulb';

    if (index === 1) return 'fa-circle-dot';

    if (index === 2) return 'fa-calendar-days';

    if (index === 3) return 'fa-chart-bar';

    return 'fa-circle-check';
  };

  return (
    <section className={pageStyles.pageSection}>
      <div className={commonStyles.container}>
        <article className={pageStyles.pageSectionColumn}>
          <div className={styles.stepsHeading}>
            <div className={commonStyles.sectionTitle}>
              <HeadingLine />
              <HeadingLine />
              <h3 className={pageStyles.pageSectionTitleContent}>
                {t('about.sections.steps.title')}
              </h3>
            </div>
            <h2>{t('about.sections.steps.subtitle')}</h2>
            <h6>{t('about.sections.steps.section-subtitle')}</h6>
          </div>
        </article>
        <div
          className={classNames(
            pageStyles.pageSectionColumn,
            styles.stepsWrapper,
          )}>
          <div className={styles.stepsList}>
            {steps.map((step, index) => (
              <Step
                key={step.value}
                label={step.label}
                stepNumber={`${t('about.sections.steps.keyword')} ${index + 1}`}
                iconName={getIconName(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;
