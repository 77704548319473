const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      suspense: false,
    },
  },
};

const QueryKeys = {
  Packages: 'Packages',
  Testimonials: 'Testimonials',
  PackageDetail: 'Package',
  Recipes: 'Recipes',
  RecipeDetail: 'RecipeDetail',
  Information: 'Information',
  Services: 'Services',
  ServiceDetail: 'ServiceDetail',
};

export { queryClientConfig, QueryKeys };
