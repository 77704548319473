import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';

import DEFAULT_IMAGE_ALT, { IS_DEV } from '../../../constants/global-constants';
import routes from '../../../constants/routes';

import dailyPlanImg from '../../../images/dailyplan.png';
import recipes from '../../../images/recipes.png';

import commonsStyles from '../../styles/index.module.scss';
import styles from './Banner.module.scss';

const Banner = () => {
  const { t } = useTranslation();

  const handleRedirect = (pathname: 'pachete' | 'retete') => {
    window.location.href = `${IS_DEV ? '/sergiumatei/' : '/'}${pathname}`;
  };

  const isHome = window.location.pathname === routes.home;

  return (
    <section
      className={classNames({
        [styles.bannerModule]: isHome,
        [styles.bannerModuleDefault]: !isHome,
      })}
      data-testid='homepage-banner'>
      <div
        className={classNames(commonsStyles.container, styles.bannerContainer)}>
        <div className={styles.bannerContent}>
          <h3 className={styles.bannerMotto}>
            <span>{t('home.banner.motto-line-one')}</span>
            <span>{t('home.banner.motto-line-two')}</span>
          </h3>
          <p className={styles.bannerDescription}>
            {t('home.banner.description')}
          </p>
          <div className={styles.bannerButtons}>
            <Button
              label={t('menu.packages')}
              variant='secondary'
              onClick={() => handleRedirect('pachete')}>
              <Icon
                name='fa-regular fa-circle-chevron-right'
                size={14}
                color='icon-white'
              />
            </Button>
            <Button
              label={t('menu.recipes')}
              variant='secondary'
              onClick={() => handleRedirect('retete')}
            />
          </div>
          <div className={styles.bannerServices}>
            <div className={styles.bannerService}>
              <img src={dailyPlanImg} alt='Nutrition' loading='lazy' />
              <span>{t('home.banner.meal-plan')}</span>
            </div>
            <div className={styles.bannerService}>
              <img src={recipes} alt='Nutrition' loading='lazy' />
              <span>{t('home.banner.recipes')}</span>
            </div>
          </div>
        </div>
        <img
          loading='lazy'
          src='https://sergiumatei.ro/wp-content/uploads/2024/04/sergiumatei.png'
          alt={DEFAULT_IMAGE_ALT}
          className={styles.bannerImage}
        />
      </div>
    </section>
  );
};

export default Banner;
