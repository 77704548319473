import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TServicesResponse } from '../../types/TServicesResponse';

const useGetServices = () => {
  const { data, isLoading, error, refetch } = request<TServicesResponse>(
    `${BASE_URL}/api/v1/services`,
    QueryKeys.Services,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetServices;
