import { IS_DEV } from './global-constants';

const BASE_PATH = IS_DEV ? '/sergiumatei/' : '/';

const menu = [
  {
    id: 1,
    title: 'home',
    url: BASE_PATH,
  },
  {
    id: 2,
    title: 'about',
    url: `${BASE_PATH}despre`,
  },
  {
    id: 3,
    title: 'packages',
    url: `${BASE_PATH}pachete`,
  },
  {
    id: 4,
    title: 'recipes',
    url: `${BASE_PATH}retete`,
  },
  {
    id: 5,
    title: 'services',
    url: `${BASE_PATH}servicii`,
  },
  {
    id: 6,
    title: 'testimonials',
    url: `${BASE_PATH}testimoniale`,
  },
];

export const contactPage = {
  id: 6,
  title: 'contact',
  url: `${BASE_PATH}contact`,
};

export default menu;
