import React from 'react';

import Routes from './routing/Routes';

function App() {
  return (
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  );
}

export default App;
