import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsResources from './translations';

i18n.use(initReactI18next).init({
  resources: translationsResources,
  lng: 'ro',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'ro',
  compatibilityJSON: 'v3',
});

export default i18n;
