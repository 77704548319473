import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Contact from './components/Contact/Contact';
import Education from './components/Education/Education';
import Experience from './components/Experience/Experience';
import Steps from './components/Steps/Steps';
import Story from './components/Story/Story';

import DEFAULT_IMAGE_ALT from '../../constants/global-constants';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './AboutUs.module.scss';

const AboutUs = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.about')} | ${t('general.website-name')}`,
  });

  return (
    <div className={styles.pageContainer}>
      <h3>{t('about.headline')}</h3>
      <div className={classNames(styles.pageBanner, commonStyles.container)}>
        <img
          src='https://sergiumatei.ro/wp-content/uploads/about-me.jpg'
          alt={DEFAULT_IMAGE_ALT}
          loading='lazy'
        />
      </div>
      <Story />
      <Education />
      <Experience />
      <Steps />
      <Contact />
    </div>
  );
};

export default AboutUs;
