import classNames from 'classnames';

import getKeyForCondition from '../../helpers/helpers';

type TIconColor =
  | 'icon-default'
  | 'icon-accent'
  | 'icon-secondary-accent'
  | 'icon-tertiary-accent'
  | 'icon-white'
  | 'icon-black'
  | 'icon-link'
  | 'icon-text';

type TProps = {
  name: string;
  color: TIconColor;
  size?: number;
  iconStyle?: 'fas' | 'far';
  testId?: string;
};

const Icon = ({
  name,
  color,
  size = 12,
  iconStyle = 'fas',
  testId,
}: TProps) => {
  return (
    <i
      className={classNames(iconStyle, name)}
      style={{
        fontSize: `${size}px`,
        color: getKeyForCondition({
          'var(--icon-default)': color === 'icon-default',
          'var(--icon-primary-accent)': color === 'icon-accent',
          'var(--icon-secondary-accent)': color === 'icon-secondary-accent',
          'var(--icon-tertiary-accent)': color === 'icon-tertiary-accent',
          'var(--icon-white)': color === 'icon-white',
          'var(--icon-black)': color === 'icon-black',
          'var(--icon-link)': color === 'icon-link',
          'var(--icon-text)': color === 'icon-text',
        }),
      }}
      data-testid={testId}></i>
  );
};

export default Icon;
