import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';

import commonStyles from '../../../../shared/styles/index.module.scss';
import pageStyles from '../../AboutUs.module.scss';
import styles from './Education.module.scss';

const Education = () => {
  const { t } = useTranslation();

  return (
    <section className={pageStyles.pageSection}>
      <article className={commonStyles.container}>
        <div
          className={classNames(
            pageStyles.pageSectionRow,
            styles.educationSection,
          )}>
          <div>
            <img
              src='https://sergiumatei.ro/wp-content/uploads/education.png'
              alt='Education'
              loading='lazy'
            />
          </div>
          <div>
            <div className={commonStyles.sectionTitle}>
              <HeadingLine />
              <HeadingLine />
              <h3 className={pageStyles.pageSectionTitleContent}>
                {t('about.sections.education.title')}
              </h3>
            </div>
            <div className={styles.experienceRow}>
              <div className={styles.experienceTitle}>
                <h6>{t('about.sections.education.institution')}</h6>
              </div>
              <div className={styles.experiencePeriod}>
                {t('about.sections.education.master.period')}
              </div>
              <p className={styles.experienceDescription}>
                {t('about.sections.education.master.period-desc')}
              </p>
            </div>
            <div className={styles.experienceRow}>
              <div className={styles.experienceTitle}>
                <h6>{t('about.sections.education.institution')}</h6>
              </div>
              <div className={styles.experiencePeriod}>
                {t('about.sections.education.bachelor.period')}
              </div>
              <p className={styles.experienceDescription}>
                {t('about.sections.education.bachelor.period-desc')}
              </p>
            </div>
            <div className={styles.experienceRow}>
              <div className={styles.experienceTitle}>
                <h6>{t('about.sections.education.conferences')}</h6>
              </div>
              <div className={styles.experiencePeriod}>
                {t('about.sections.education.conference.period')}
              </div>
              <p className={styles.experienceDescription}>
                {t('about.sections.education.conference.period-desc')}
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Education;
