import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import useGetRecipe from '../../shared/hooks/useGetRecipe/useGetRecipe';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Spinner from '../../shared/components/Spinner/Spinner';

import funFactImg from '../../images/fun-fact.png';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './RecipeDetail.module.scss';

const RecipeDetail = () => {
  const { t } = useTranslation();

  const urlSplit = window.location.href.split('/');
  const recipeId = urlSplit[urlSplit.length - 1].split('?')[0];

  const recipeDescriptionRef = React.useRef<HTMLDivElement>(null);

  const { data, isLoading, refetch } = useGetRecipe(Number(recipeId), false);

  useSetPageTitle({
    pageTitle: data?.title
      ? `${data.title} | ${t('general.website-name')}`
      : t('general.website-name'),
  });

  React.useEffect(() => {
    if (recipeId) {
      refetch();
    }
  }, [recipeId]);

  React.useEffect(() => {
    if (recipeDescriptionRef.current !== null && data) {
      const recipeSteps = recipeDescriptionRef.current.querySelectorAll('li');

      recipeSteps.forEach((item, index) => {
        const stepNumber = document.createElement('span');
        stepNumber.innerHTML = `${index + 1}`;
        item.prepend(stepNumber);
      });
    }
  }, [recipeDescriptionRef, data]);

  return (
    <div>
      {isLoading ? (
        <Spinner variant='accent' />
      ) : (
        <div
          className={classNames(commonStyles.container, styles.recipeContent)}>
          <section>
            {data && (
              <>
                <div className={styles.recipeHeader}>
                  <img
                    src={data.featured_image}
                    alt={data.title}
                    loading='lazy'
                  />
                  <div className={styles.recipeHeaderContent}>
                    <h1>{data.title}</h1>
                  </div>
                </div>
                <div className={styles.recipeDetails}>
                  <div className={styles.recipeSteps}>
                    <div ref={recipeDescriptionRef}>
                      <h3>{t('recipe-details.instructions')}</h3>
                      <div className={styles.recipeDescription}>
                        {parse(data.description)}
                      </div>
                      <div className={styles.recipeFunFact}>
                        <img src={funFactImg} alt='Stiati ca?' loading='lazy' />
                        <p>{parse(data.fact)}</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.recipeIngredients}>
                    <div className={styles.recipeIngredientsBlock}>
                      <h3>{t('recipe-details.ingredients')}</h3>
                      <div className={styles.recipeAdditionalInfo}>
                        <p>
                          <span>{t('recipe-details.portions')}</span>
                          <span>{data.portions}</span>
                        </p>
                        <p>
                          <span>{t('recipe-details.time')}</span>
                          <span>{data.preparation_time}</span>
                        </p>
                        <p>
                          <span>{t('recipe-details.preparation-mode')}</span>
                          <span>{data.preparation_mode}</span>
                        </p>
                      </div>
                      <hr className={styles.separator} />
                      <div>{parse(data.ingredients)}</div>
                    </div>
                    <div className={styles.recipeIngredientsBlock}>
                      <h3>{t('recipe-details.nutritional-values')}</h3>
                      <div>{parse(data.nutritional_values)}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

export default RecipeDetail;
