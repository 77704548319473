import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { contactPage } from '../../../constants/menu';

import { TMenu } from '../../types/TMenu';
import Icon from '../Icon/Icon';

import styles from './Menu.module.scss';

type TProps = {
  isBurgerMenuToggled: boolean;
  items: TMenu;
};

const Menu = ({ isBurgerMenuToggled, items }: TProps) => {
  const { t } = useTranslation();

  return (
    <nav
      className={classNames(styles.navigationMenuWrapper, {
        [styles.menuOnMobile]: isBurgerMenuToggled,
      })}
      data-testid='primary-nav-wrap'>
      <ul className={styles.navigationMenu}>
        {items.map(({ id, url, title }) => {
          const page = `/${window.location.href
            .split('/')
            .filter((item) => Boolean(item))
            .at(-1)}`;
          const isActive = page === url;

          const commonProps = {
            key: id,
            rel: 'noopener noreferrer',
            className: styles.menuItemAnchor,
          };

          return (
            <li
              key={id}
              className={classNames(styles.menuItem, {
                [styles.menuItemActive]: isActive,
              })}
              data-testid={`menu-item-${id}`}>
              <a
                {...commonProps}
                href={url}
                target='_self'
                data-testid={`main-menu-anchor-${id}`}>
                {t(`menu.${title}`)}
              </a>
            </li>
          );
        })}
      </ul>
      <ul className={styles.navigationMenu}>
        <li
          className={classNames({
            [styles.menuItemActive]:
              `/${window.location.href.split('/').at(-1)}` === contactPage.url,
          })}
          data-testid={`menu-item-${contactPage.id}`}>
          <a
            className={styles.menuItemButtonLike}
            href={contactPage.url}
            target='_self'
            data-testid={`main-menu-anchor-${contactPage.id}`}>
            <span>{t(`menu.${contactPage.title}`)}</span>
            <Icon name='fa-phone' size={16} color='icon-text' />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
