import { useTranslation } from 'react-i18next';

import useGetServices from '../../shared/hooks/useGetServices/useGetServices';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import HeadingLine from '../../shared/components/HeadingLine/HeadingLine';
import Spinner from '../../shared/components/Spinner/Spinner';

import { IS_DEV } from '../../constants/global-constants';

import { TService } from '../../shared/types/TServicesResponse';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './Services.module.scss';

const Services = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.services')} | ${t('general.website-name')}`,
  });

  const { data, isLoading } = useGetServices();
  const BASE_PATH = IS_DEV ? '/sergiumatei/' : '/';

  return (
    <div className={styles.pageContainer}>
      <section className={commonStyles.container}>
        <div className={styles.pageHeader}>
          <div className={commonStyles.sectionTitle}>
            <HeadingLine />
            <HeadingLine />
            <h3 className={styles.pageSectionTitleContent}>
              {t('services.subtitle')}
            </h3>
          </div>
          <h1>{t('services.title')}</h1>
        </div>
        <div className={styles.servicesList}>
          {isLoading ? (
            <Spinner variant='black' />
          ) : (
            data?.services.map((service: TService) => (
              <article className={styles.serviceItem} key={service.id}>
                <div className={styles.serviceImage}>
                  <img src={service.image} alt={service.title} loading='lazy' />
                </div>
                <div>
                  <h4>
                    <a href={`${BASE_PATH}serviciu/${service.id}`}>
                      {service.title}
                    </a>
                  </h4>
                  <p>{service.content}</p>
                </div>
              </article>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default Services;
