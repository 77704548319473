import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import useGetPackages from '../../shared/hooks/useGetPackages/useGetPackages';
import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Package from './components/Package/Package';
import Spinner from '../../shared/components/Spinner/Spinner';
import Step from '../AboutUs/components/Steps/components/Step';

import { TPackage } from '../../shared/types/TPackagesResponse';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './Packages.module.scss';

const Packages = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPackages();

  useSetPageTitle({
    pageTitle: `${t('pages.packages')} | ${t('general.website-name')}`,
  });

  const steps = [
    {
      value: 'one',
      label: t('about.sections.steps.protocol-implementation.step-one'),
    },
    {
      value: 'two',
      label: t('about.sections.steps.protocol-implementation.step-two'),
    },
    {
      value: 'three',
      label: t('about.sections.steps.protocol-implementation.step-three'),
    },
    {
      value: 'four',
      label: t('about.sections.steps.protocol-implementation.step-four'),
    },
    {
      value: 'five',
      label: t('about.sections.steps.protocol-implementation.step-five'),
    },
  ];

  const getIconName = (index: number) => {
    if (index === 0) return 'fa-file';

    if (index === 1) return 'fa-credit-card';

    if (index === 2) return 'fa-file-lines';

    if (index === 3) return 'fa-lightbulb';

    return 'fa-file-pdf';
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.packagesHeader}>
        <div
          className={classNames(styles.packagesText, commonStyles.container)}>
          <h1>{t('packages.title')}</h1>
          <p>{t('packages.subtitle')}</p>
        </div>
      </div>
      {isLoading ? (
        <Spinner variant='accent' />
      ) : (
        <section className={commonStyles.container}>
          <div className={styles.stepsHeading}>
            <h2>{t('packages.steps-heading')}</h2>
            <p>{t('packages.steps-timeline')}</p>
          </div>
          <div className={styles.stepsList}>
            {steps.map((step, index) => (
              <Step
                key={step.value}
                label={step.label}
                stepNumber={`${t('about.sections.steps.keyword')} ${index + 1}`}
                iconName={getIconName(index)}
              />
            ))}
          </div>
          <div className={styles.packagesList}>
            {data && data.packages.length > 0 && (
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 540: 1, 768: 2, 992: 3 }}>
                <Masonry gutter='24px'>
                  {data.packages.map(
                    ({
                      id,
                      description,
                      features,
                      price_max,
                      price_min,
                      title,
                    }: TPackage) => (
                      <Package
                        key={id}
                        id={id}
                        description={parse(description)}
                        features={parse(features)}
                        price_max={price_max}
                        price_min={price_min}
                        title={title}
                      />
                    ),
                  )}
                </Masonry>
              </ResponsiveMasonry>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Packages;
