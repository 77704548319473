import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import illustration404 from '../../images/404-illustration.png';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './404.module.scss';

const Page404 = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.404')} | ${t('general.website-name')}`,
  });

  return (
    <div className={styles.page404}>
      <div
        className={classNames(styles.page404Container, commonStyles.container)}>
        <div className={styles.page404Image}>
          <img src={illustration404} alt='404' loading='lazy' />
        </div>
        <div className={styles.page404Details}>
          <h1>{t('404.subtitle')}</h1>
          <h6>{t('404.description')}</h6>
        </div>
      </div>
    </div>
  );
};

export default Page404;
