import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TTestimonialsResponse } from '../../types/TTestimonialsResponse';

type TProps = {
  offset?: string;
};

const useGetTestimonials = ({ offset }: TProps) => {
  const { data, isLoading, error, refetch } = request<TTestimonialsResponse>(
    `${BASE_URL}/api/v1/testimonials?offset=${offset}`,
    QueryKeys.Testimonials,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetTestimonials;
