import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useSetPageTitle from '../../shared/hooks/useSetPageTitle/useSetPageTitle';

import Icon from '../../shared/components/Icon/Icon';

import {
  EMAIL,
  FB_URL,
  IG_URL,
  PHONE_NUMBER,
} from '../../constants/global-constants';

import commonStyles from '../../shared/styles/index.module.scss';
import styles from './Contact.module.scss';

const Contact = () => {
  const { t } = useTranslation();

  useSetPageTitle({
    pageTitle: `${t('pages.contact')} | ${t('general.website-name')}`,
  });

  return (
    <div className={styles.contactPage}>
      <div
        className={classNames(
          styles.contactPageContainer,
          commonStyles.container,
        )}>
        <div className={styles.contactImage}>
          <img
            src='https://sergiumatei.ro/wp-content/uploads/contact-illustration.png'
            alt='Contact'
            loading='lazy'
          />
        </div>
        <div className={styles.contactDetails}>
          <h1>{t('contact.title')}</h1>
          <p className={styles.contactDetailLine}>
            <span>{t('contact.phone')}</span>
            <span>{PHONE_NUMBER}</span>
          </p>
          <p className={styles.contactDetailLine}>
            <span>{t('contact.email')}</span>
            <span>{EMAIL}</span>
          </p>
          <div className={styles.contactSocialMedia}>
            <a href={FB_URL} target='_blank' rel='noopener noreferrer'>
              <Icon name='fa-brands fa-facebook' size={24} color='icon-black' />
            </a>
            <a href={IG_URL} target='_blank' rel='noopener noreferrer'>
              <Icon
                name='fa-brands fa-instagram'
                size={24}
                color='icon-black'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
