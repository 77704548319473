import request from '../../helpers/request';

import { BASE_URL } from '../../../constants/global-constants';
import { QueryKeys } from '../../../config/react-query';

import { TRecipesResponse } from '../../types/TRecipesResponse';

const useGetRecipes = () => {
  const { data, isLoading, error, refetch } = request<TRecipesResponse>(
    `${BASE_URL}/api/v1/recipes`,
    QueryKeys.Recipes,
    true,
  );

  return { data, isLoading, error, refetch };
};

export default useGetRecipes;
