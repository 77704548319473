import React from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import classNames from 'classnames';

import useIsInViewport from '../../../../shared/hooks/useIsInViewport/useIsInViewport';
import useGetInformation from '../../../../shared/hooks/useGetInformation/useGetInformation';

import HeadingLine from '../../../../shared/components/HeadingLine/HeadingLine';

import DEFAULT_IMAGE_ALT from '../../../../constants/global-constants';

import customersImg from '../../../../images/customers.png';
import cuttleryImg from '../../../../images/cuttlery.png';
import progressImg from '../../../../images/progress.png';

import commonStyles from '../../../../shared/styles/index.module.scss';
import pageStyles from '../../AboutUs.module.scss';
import styles from './Story.module.scss';

type TCounterBlockProps = {
  endValue: number;
};

const CounterBlock = ({ endValue }: TCounterBlockProps) => (
  <CountUp delay={0.5} duration={3} end={endValue} suffix='+' />
);

const Story = () => {
  const { t } = useTranslation();
  const elementRef = React.useRef(null);
  const [hasShownCounters, setHasShownCounters] = React.useState(false);

  const { isInViewport } = useIsInViewport({ elementRef });

  const { data } = useGetInformation();

  const counterValues = {
    years_of_experience: data?.years_of_experience,
    customers: data?.customers,
    recipes: data?.recipes,
  };

  React.useEffect(() => {
    if (!hasShownCounters && isInViewport) {
      setHasShownCounters(true);
    }
  }, [hasShownCounters, isInViewport]);

  return (
    <section
      className={classNames(pageStyles.pageSection, styles.storySection)}>
      <div className={commonStyles.container}>
        <article className={pageStyles.pageSectionColumn}>
          <div>
            <div className={commonStyles.sectionTitle}>
              <HeadingLine />
              <HeadingLine />
              <h3 className={pageStyles.pageSectionTitleContent}>
                {t('about.sections.story.title')}
              </h3>
            </div>
            <h2>{t('about.sections.story.subtitle')}</h2>
            <p>{t('about.sections.story.description-line-one')}</p>
            <p>{t('about.sections.story.description-line-two')}</p>
            <div ref={elementRef}>
              {hasShownCounters && (
                <div className={styles.statistics}>
                  <div className={styles.statisticBlock}>
                    <img src={progressImg} alt='Progress' loading='lazy' />
                    <div>
                      <h4>
                        <CounterBlock
                          endValue={counterValues.years_of_experience}
                        />
                      </h4>
                      <span>
                        {t('home.about.statistics.years-of-experience')}
                      </span>
                    </div>
                  </div>
                  <div className={styles.statisticBlock}>
                    <img
                      src={customersImg}
                      alt='Satisfied customers'
                      loading='lazy'
                    />
                    <div>
                      <h4>
                        <CounterBlock endValue={counterValues.customers} />
                      </h4>
                      <span>{t('home.about.statistics.customers')}</span>
                    </div>
                  </div>
                  <div className={styles.statisticBlock}>
                    <img src={cuttleryImg} alt='Cuttlery' loading='lazy' />
                    <div>
                      <h4>
                        <CounterBlock endValue={counterValues.recipes} />
                      </h4>
                      <span>{t('home.about.statistics.recipes')}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </article>
        <div className={pageStyles.pageSectionColumn}>
          <div className={pageStyles.imageWrapper}>
            <img
              src='https://sergiumatei.ro/wp-content/uploads/2024/04/sergiumatei.png'
              alt={DEFAULT_IMAGE_ALT}
              loading='lazy'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Story;
